import { createApp } from 'vue'
import App from './App.vue'
import "./assets/scss/main.scss"
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar Bootstrap CSS
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTrophy)
library.add(faStar)
library.add(faFlagCheckered)

import router from './router'

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.mount('#app');