<template>
  <header class="header">
    <div class="header__preheader max-container">
      <a href="/" class="header__preheader--logo">
          <img class="header-content__img" src="../assets/images/logo.png" alt="wagerStore" />
      </a>
      <a href="tel:+ 1-888-770-2387" class="header__preheader--phonenumber">
        Call Wagering 1-888-770-2387
      </a>
      
    </div>
    
    <div class="login">
      <form id="loginForm" class="login__form" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
        <div class="login__form--inputs">
          <div class="login__form--item">
            <label class="login__form--label" for="username">USERNAME</label>
            <input class="login__form--input" name="username" id="username" type="text" required placeholder="USERNAME" style="border-color: #9d9e9e"/>
          </div>
          <div class="login__form--item">
            <label class="login__form--label" for="password">PASSWORD</label>
            <input class="login__form--input" type="password" name="password" id="password" required placeholder="PASSWORD"/>
          </div>
          <div class="text-center mt-2">
            <span name="msj_loading" id="id-login-loading"></span>
            <small class="text-danger" name="msj_error_lg"></small>
          </div>
        </div>
        <div class="login__form--btnContainer">
          <input type="submit" class="login__form--button" name="send" id="send" value="LOG IN">
          <input type="hidden" name="BackEndUrl" :value="`https://betslip.${backendUrl}`">
        </div>
      </form>
    </div>

    <div class="header-content max-container">
      <div class="header-content__nav">
        <a href="/" class="header-content__container-logo">
          <img class="header-content__img" src="../assets/images/logo.png" alt="wagerStore" />
        </a>
        <button class="header-content__burger" @click="toggleMenu">
          <span :class="['burger-line', { 'line1': isOpen }]"></span>
          <span :class="['burger-line', { 'line2': isOpen }]"></span>
          <span :class="['burger-line', { 'line3': isOpen }]"></span>
        </button>
      </div>
      
      <div :class="['header-content__second', { 'is-open': isOpen }]">
        <a href="/" class="header-content__second--logo">
          <img class="header-content__img" src="../assets/images/logo.png" alt="wagerStore" />
        </a>
        <ul class="header-content__info--list">
          <li class="header-content__info--list-item">
            <router-link to="/sportsbook">SPORTS</router-link>
          </li>
          <li class="header-content__info--list-item">
            <router-link to="/live-betting">LIVE WAGER</router-link>
          </li>
          <li class="header-content__info--list-item">
            <a href="https://agents.abcbet.ag/AgentSiteV2/" target="_blank">AGENTS</a>
          </li>
        </ul>
      </div>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from '@/components/modal.vue';

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
  },
  setup() {
    const DGS_SITEID = 592;
    const backendUrl = "abcbet.ag";
    return {
      DGS_SITEID,
      backendUrl,
    }
  },
  data() {
    return {
      siteUrl: "abcbet.ag",
      isOpen: false,
    }
  },
  methods: {
    openModal() {
      const screenWidth = window.innerWidth;
      const DgsBackendUrl = screenWidth <= 764 ? `//mobile.${this.siteUrl}/DefaultLogin.aspx` : `//wager.${this.siteUrl}/DefaultLogin.aspx`;
      this.$refs.modal.openModal(DgsBackendUrl);
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    moveLoginForm() {
      const form = document.querySelector('.login');
      const preheader = document.querySelector('.header__preheader');
      const nav = document.querySelector('.header-content');

      if (window.innerWidth <= 1024) {
        // Mueve el formulario al nav en móviles
        if (nav && !nav.contains(form)) {
          nav.appendChild(form);
        }
      } else {
        // Mueve el formulario a la preheader en escritorio
        if (preheader && !preheader.contains(form)) {
          preheader.appendChild(form);
        }
      }
    }
  },
  mounted() {
    this.moveLoginForm();
    window.addEventListener('resize', this.moveLoginForm);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.moveLoginForm);
  }
};
</script>

<style scoped>

.login__form--input {
  border: 1px solid #9d9e9e;
  margin-top: 5px;
}


.header-content__info--list-item a {
  color: #fff;
  text-decoration: none;
  margin-right: 50px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.header li a {
  display: inline;
  font-weight: bold;
  font-size: 14px;
}


#footer {
  text-align: center;
}

</style>
