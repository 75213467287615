<template>
<footer>
  <div class="footer">
    <div class="sub-footer container">
      <div class="row mt-3 footer-link justify-content-center">
        <div class="col-12 col-md-4">
          <router-link to="/responsible-gaming" class="link">Responsible Gaming</router-link>
          <router-link to="/privacy-policy" class="link">Privacy Policy</router-link>
          <router-link to="/terms-conditions" class="link">Terms and Conditions</router-link>
        </div>

        <div class="col-12 col-md-4">
          <router-link to="/info-contact" class="link">Contact Us</router-link>
          <router-link to="/calculadora-parlay" class="link">Parlay Calculator.</router-link>
          <a href="https://agents.abcbet.ag/AgentSiteV2/" target="_blank" class="link">Agents</a>
          <a href="https://adm.abcbet.ag/" target="_blank" class="link">New Agents</a>
        </div>

        <div class="col-12 col-md-4">
          <router-link to="/house-rules" class="link">House Rules</router-link>
          <router-link to="/horse-rules" class="link">Horse Rules</router-link>
          <router-link to="/sports-rules" class="link">Sport Rules</router-link>
          <router-link to="/same-game-parlay-rules" class="link">Same Game Parlay Rules</router-link>
        </div>
      </div>
    </div>
  </div>
</footer>


</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      return {
        routes: [
          {
            name       :  'sportsbook',
            route      :  'sportsbook'
          },
          {
            name       :  'live betting',
            route      :  'live-betting'
          },
          {
            name       :  'racebook',
            route      :  'racebook'
          },
          {
            name       :  'casino',
            route      :  'casino'
          },
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          }
        ],
        rules: [
          {
            name       :  'Reglas de casino',
            route      :  'reglas-casino'
          },
          // {
          //   name       :  'Reglas de deportes',
          //   route      :  'sports-rules'
          // },
          {
            name       :  'Reglas de la casa',
            route      :  'house-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'Reglas de parlay del mismo equipo'
          },
             {
            name       :  'Afiliados',
            link      :  'https://afiliados.mrsreels.com/home/landing'
          },
        ],
        legal: [
          {
            name       :  'Terminos y condiciones',
            route      :  'terminos-condiciones'
          },
          {
            name       :  'Juego responsable',
            route      :  'juego-responsable'
          },
          {
            name       :  'Auto exclusión',
            route      :  'auto-exclusion'
          },
        ],
        regulations: [
          {
            name       :  'Política de reclamos',
            route      :  'politica-reclamos'
          },
          {
            name       :  'Política de anti lavado',
            route      :  'anti-lavado'
          },
          {
            name       :  'Juego justo',
            route      :  'juego-justo'
          },
        ],
        privacy: [
          {
            name       :  'Política de privacidad',
            route      :  'politica-privacidad'
          },
          {
            name       :  'Política de pago',
            route      :  'politica-pago'
          },
          {
            name       :  'Conozca a su cliente',
            route      :  'conozca-cliente'
          },
        ]
      }
    },
  };
</script>